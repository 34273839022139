import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`How to configure your development environment for working on KOPE.`}</p>
    </PageDescription>
    <p>{`This guide assumes a level of familiarity with the `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/dotnet/csharp/"
      }}>{`C# programming language`}</a>{` and general `}<a parentName="p" {...{
        "href": "https://dotnet.microsoft.com/"
      }}>{`.NET development`}</a>{`.
You need to have installed a development environment, such as `}<a parentName="p" {...{
        "href": "https://visualstudio.microsoft.com/"
      }}>{`Visual Studio 2022`}</a>{` in which to write your code.`}</p>
    <h3>{`Visual Studio Installation`}</h3>
    <p>{`If you haven’t installed Visual Studio before, you’ll need to install it.
If you already have Visual Studio, but are unsure whether you have the correct components, you can still open the Visual Studio Installer to check/modify your installation.
KOPE currently supports versions:`}</p>
    <ul>
      <li parentName="ul">{`Visual Studio 2019 (v16.8 or higher)`}</li>
      <li parentName="ul">{`Visual Studio 2022 (any version)`}</li>
    </ul>
    <br />
During the installation process, the Visual Studio Installer will ask whether you want to install specific components for certain types of development. For KOPE development, you will need:
    <br />
    <ol>
      <li parentName="ol">{`The `}<strong parentName="li">{`.NET Desktop Development`}</strong>{` component.`}</li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Individual Components`}</strong>{` select the `}<strong parentName="li">{`.NET Core 3.1 Runtime (LTS)`}</strong>{`.`}</li>
      <li parentName="ol">{`In the same tab, ensure you select the `}<strong parentName="li">{`.NET 5.0 Runtime`}</strong>{` (or any higher version of this).`}</li>
    </ol>
    <img {...{
      "src": "/039994a64548ab500656dd89c2686938/visual-studio-setup.gif",
      "alt": "visual-studio-setup.gif"
    }}></img>
    <br />
    <p>{`Once selected, click Install to install Visual Studio (and put the kettle on, this will take a while).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      